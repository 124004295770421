import { useRef, useState } from "react";
import {post} from '../../helpers/api_helper';
import { POST_REGISTER } from '../../helpers/url_helper';
import { setUserSession } from '../../helpers/Helper';

import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

export default function Model() {
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ name:'',email: '', password: '',tc : false });
    const [, forceUpdate] = useState();

     // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_REGISTER, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                setUserSession(res_data.data.token, res_data.data);
                document.getElementById('updateLogin').click();
                window.$("#loginsignup").modal("hide");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if(event.target.name === 'tc'){
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.checked }));
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    return (<>
        <div className="titlebox"><h3>User Register</h3></div>
        <div className="form_wrap">
            <form action="" onSubmit={handleSubmit}>
                <div className="form_row">
                    <input type="text" name="name" onChange={handleInputChange} value={form_inputs.name} className="input_text" placeholder="Name:" />
                    {simpleValidator.current.message('name', form_inputs.name, 'required')}
                </div>
                <div className="form_row">
                    <input type="text" name="email" onChange={handleInputChange} value={form_inputs.email} className="input_text" placeholder="Email Id:" />
                    {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                </div>
                <div className="form_row">
                    <input type="password" name="password" onChange={handleInputChange} value={form_inputs.password} className="input_text" placeholder="Password:" />
                    {simpleValidator.current.message('password', form_inputs.password, 'required')}
                </div>
                <div className="form_row">
                    <div className="checkrow">
                        <input type="checkbox" name="tc" checked={form_inputs.tc} onChange={handleInputChange} id="termsandcondition" />
                        <label htmlFor="termsandcondition">Accept Terms & Conditions - <a href="#">View</a></label>
                        {!form_inputs.tc && simpleValidator.current.message('tc', '', 'required')}
                        {form_inputs.tc && simpleValidator.current.message('tc', form_inputs.tc, 'required')}
                    </div>
                </div>
                <div className="form_row btnbox">
                    <button type="submit" disabled={ButtonDisabled} className="ondabtn">{ButtonDisabled && <i className="spinner"></i>} Register</button>
                </div>
            </form>
        </div>
    </>)
}
