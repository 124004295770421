import { useState, useEffect, useContext } from "react";

import ImageCaro from './../component/ImageCaro'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { img_view } from '../helpers/Helper';
import { Link } from "react-router-dom";
import { MenuContext } from '../layouts/Default';

export default function Home(props) {
    const { categories, homeBanner } = useContext(MenuContext)
    const [OrderOnlineLink, SetOrderOnlineLink] = useState('/');

    useEffect(() => {
        async function get_detaile() {
            if (categories.length > 0) {
                SetOrderOnlineLink('/menu/' + categories[0].category_name);
            }
        }
        get_detaile();
    }, [])

    return (<>

        <div className="banner_wrapper">
            <div class="gf_logo"><img src="/assets/images/gf_logo.svg" alt=""/></div>
            <div className="homebanner">
                <div className="bann_captionbox">
                    <div className="container">
                        <div className="btnbox">
                            <Link to={OrderOnlineLink} className="ondabtn bigbtn"><img src="/assets/images/order_online_icon.svg" alt="" />Order Online</Link>
                            <Link to="/table-booking" className="ondabtn greenbtn bigbtn"><img src="/assets/images/book_table_icon.svg" alt="" />Book a Table</Link>
                        </div>
                        <div className="btnbox gray">
                            <Link to="/bottomless-brunch" className="ondabtn graybtn bigbtn"><img src="/assets/images/bottomless_brunch_icon.svg" alt="" />Book Bottomless Brunch</Link>
                        </div>
                    </div>
                </div>
                <OwlCarousel className='home_banner_slider owl-theme' loop margin={0} items={1} autoplay={true}
                    autoplayTimeout={5000}
                    autoplayHoverPause={true}
                    nav={false} dots={true} animateOut={'fadeOut'} dotsContainer={'#MyDots'} >
                    {homeBanner && Object.entries(homeBanner).map(([key, banner]) => {
                         return (<><div className='item'>
                            <div className="imagebox" style={{ 'backgroundImage': `url(${img_view(banner.image_name)})` }}>
                                <h2>{banner.title}</h2>
                            </div>
                        </div></>)
                    })}
                </OwlCarousel>
                <div id="MyDots" className="owl-dots homedots flxrow"></div>
            </div>
        </div>


        <div className="pro_category_wrap">
            <div className="container">
                <div className="in_pro_category">
                    <div className="row">
                        {categories && Object.entries(categories).map(([key, menu]) => {
                            return (<><div className="col-lg-3 col-md-6" key={key}>
                                <Link to={`/menu/${menu.category_name}`} className="catbox">
                                    <div className="iconbox flxrow">
                                        <img src={img_view(menu.category_image, '', '', false)} alt="" />
                                    </div>
                                    <div className="namebox">{menu.category_name}</div>
                                </Link>
                            </div></>)
                        })}
                    </div>
                </div>
            </div>
            <div className="shape1"><img src="/assets/images/leaf_1.png" alt="" /></div>
        </div>

        <div className="ourstory_wrap">
            <div className="container">
                <div className="storybox">
                    <div className="titlebox flxrow"><h2>Our Story</h2></div>
                    <p>We are your new special place. A place made of Latin American flavours, coupled with the warmth of home. <br />Onda is Spanish for vibes – and the feeling that we hope to spread is of good energy through our soulful meals, and familiar hospitality. Dine with us at Onda Bar & Eatery. It’s all bueno here.</p>
                </div>
            </div>
        </div>

        <div className="functionbook_sec">
            <div className="container">
                <div className="row flex-row-reverse">
                    <div className="col-lg-6">
                        <div className="imagebox"><img src="/assets/images/Home_Page_function.jpg" alt="" /></div>
                    </div>
                    <div className="col-lg-6"> 
                        <div className="captoinbox">
                            <h3>Functions Booking</h3>
                            {/* <h6>Make it a Bouzy night to remember</h6> */}
                            <p className="pt-4">Let us celebrate your special moments ONDA. We can create a package based on your requirements. Our menu is Gluten Free, It can be Onion free, garlic free and suitable for vegan/ vegetarian/ pascatarian</p>
                            <p className="pt-4">accommodates up to 60 guests and is available to book exclusively for lunch or dinner with a minimum spend of $2,500. Reservations can be made up to three months in advance. </p>
                            <p className="pt-4">For larger groups, exclusive hire our full restaurant.</p>
                            <p className="pt-4">For further information and to enquire about availability, please complete the form below and we will be in touch soon.</p>
                            <div className="cpbox">Capacity : 60</div>
                            <div className="btnbox">
                                <Link to="/function-booking" className="ondabtn bigbtn withborder">Enquiry Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape_1">
                <img src="/assets/images/leaf_2.png" alt="" />
            </div>
        </div>

        <ImageCaro />
    </>)
}
