import React, { useState, useEffect } from 'react';
import { GET_ALL_JOBS, POST_APPLICATION_SUBMIT } from '../../helpers/url_helper';
import { post } from '../../helpers/api_helper';

export default function Career() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        contact: '',
        resume: null
    });
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        try {
            const response = await post(GET_ALL_JOBS, {
                iDisplayLength: 100,
                iDisplayStart: 0,
                sSearch: '',
                sSortDir_0: 'asc',
                iSortCol_0: 1
            });
            console.log(response);


            if (response.status) {
                const activeJobs = response.aaData.filter(job => job.status === 1);
                setJobs(activeJobs);
            } else {
                setError('Unable to load job listings at this time');
            }
        } catch (err) {
            setError('Unable to load job listings at this time');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const base64 = await convertFileToBase64(file);
                setFormData(prev => ({
                    ...prev,
                    resume: base64
                }));
            } catch (err) {
                setSubmitError('Error processing resume file');
            }
        }
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = error => reject(error);
        });
    };

    const resetForm = () => {
        // Reset form data state
        setFormData({
            full_name: '',
            email: '',
            contact: '',
            resume: null
        });

        // Reset the file input by getting the element and setting its value to empty
        const fileInput = document.getElementById('resume');
        if (fileInput) {
            fileInput.value = '';
        }

        // Reset any errors
        setSubmitError(null);
    };

    const handleCareerSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        setSubmitError(null);

        try {
            if (!selectedJob) {
                throw new Error('No job selected');
            }

            const response = await post(POST_APPLICATION_SUBMIT, {
                ...formData,
                job_id: selectedJob.job_id
            });

            if (response.status) {
                // Reset the form
                resetForm();
                // Close modal
                document.querySelector('[data-bs-dismiss="modal"]').click();
                // You can add a success message here if needed
            } else {
                setSubmitError(response.message || 'Failed to submit application');
            }
        } catch (err) {
            setSubmitError('An error occurred while submitting your application');
        } finally {
            setSubmitting(false);
        }
    };

    const getDepartmentInfo = (department) => {
        const deptMap = {
            'kitchen': 'Kitchen',
            'management': 'Management',
            'front_house': 'Front of House'
        };
        return deptMap[department] || department;
    };

    const getTypeInfo = (type) => {
        const typeMap = {
            'full_time': 'Full Time',
            'part_time': 'Part Time',
            'casual': 'Casual'
        };
        return typeMap[type] || type;
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB');
    };

    return (
        <>
            <div className="banner_wrapper">
                <div className="innerbanner">
                    <div className="imagebox" style={{ "backgroundImage": "url(/assets/images/innerbanner.jpg)" }}></div>
                    <div className="bann_captionbox">
                        <div className="container-fluid">
                            <h1>Work With Us</h1>
                            <div className="opentiming">
                                Join our passionate team of culinary professionals and hospitality experts.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="career functionbooking_wrap">
                <div className="container">
                    {loading ? (
                        <div className="text-center">Loading job opportunities...</div>
                    ) : error ? (
                        <div className="text-center text-red-600">{error}</div>
                    ) : (
                        <div className="job-listings">
                            {jobs.length === 0 ? (
                                <div className="text-center">No job openings available at the moment.</div>
                            ) : (
                                jobs.map((job) => (
                                    <div className="job-card" key={job.job_id}>
                                        <h3>{job.title}</h3>
                                        <p className="text-muted">Posted on {formatDate(job.posted_on)} | Application Closing on {formatDate(job.application_closing)}</p>
                                        <p className="text-muted">
                                            {getTypeInfo(job.type)} | {getDepartmentInfo(job.department)}
                                        </p>
                                        <pre>{job.description}</pre>
                                        <button
                                            className="apply"
                                            onClick={() => {
                                                setSelectedJob(job);
                                                document.getElementById('career_model').click();
                                            }}
                                        >
                                            Apply Now
                                        </button>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            </div>

            <button type="button" id="career_model" className="none" data-bs-toggle="modal" data-bs-target="#careermodel"></button>

            <div className="modal fade pickup_planpopup" id="careermodel" tabIndex="-1" aria-labelledby="careermodelLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="model_header">
                            <h3>Enter Your Details Here</h3>
                            <button type="button" className="btn-close flxrow" data-bs-dismiss="modal" aria-label="Close">
                                <img src="/assets/images/close_icon2.svg" alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="pickupbody">
                                <div className="formbox">
                                    {submitError && (
                                        <div className="alert alert-danger">{submitError}</div>
                                    )}
                                    <form onSubmit={handleCareerSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input_box mt-0">
                                                    <label htmlFor="full_name">Full Name</label>
                                                    <input
                                                        required
                                                        type="text"
                                                        id="full_name"
                                                        name="full_name"
                                                        value={formData.full_name}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input_box mt-md-0 mt-sm-4">
                                                    <label htmlFor="email">Email</label>
                                                    <input
                                                        required
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input_box">
                                                    <label htmlFor="contact">Contact</label>
                                                    <input
                                                        required
                                                        type="tel"
                                                        id="contact"
                                                        name="contact"
                                                        value={formData.contact}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input_box">
                                                    <label htmlFor="resume">Resume / CV</label>
                                                    <input
                                                        required
                                                        className="form-input"
                                                        type="file"
                                                        id="resume"
                                                        name="resume"
                                                        accept=".pdf,.doc,.docx"
                                                        onChange={handleFileChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="btnbox">
                                                    <button
                                                        type="submit"
                                                        className="ondabtn greenbtn"
                                                        disabled={submitting}
                                                    >
                                                        {submitting ? 'Submitting...' : 'Submit'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}