import { useState, useEffect, useRef } from "react";
import { GET_TABLE_BOOK_DETAIL, POST_CANCEL_BOOKING } from '../../helpers/url_helper';
import { post } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { Link, useHistory } from "react-router-dom";


export default function Home(props) {
    let history = useHistory();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [BookingDetail, setBookingDetail] = useState({});
    const [isLoader, setLoader] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            await get_booking_detail();
            setLoader(1);
        }
        get_detaile();
    }, []);

    const get_booking_detail = async () => {
        var booking_no = props.match.params.booking_id;
        if (!booking_no) {
            history.push("/");
        }
        var res_data = await post(GET_TABLE_BOOK_DETAIL, { booking_no: booking_no });
        if (res_data.status) {
            setBookingDetail(res_data.data.booking_detail);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const cancelBooking = async () => {
        SetButtonDisabled(true);
        var res_data = await post(POST_CANCEL_BOOKING, { booking_no: BookingDetail.booking_no });
        if (res_data.status) {
            await get_booking_detail();
            window.$("#cancelTableBooking").modal("hide");
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    if (!isLoader) {
        return ('');
    }
    return (<>
        <div class="banner_wrapper">
            <div class="innerbanner">
                <div class="imagebox" style={{ "background-image": "url(/assets/images/innerbanner.jpg)" }}></div>
                <div class="bann_captionbox">
                    <div class="container-fluid">
                        <h1>Booking Info</h1>
                        <div class="opentiming">Thanks {BookingDetail.first_name}! Your booking is confirmed!</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="functionbooking_wrap">
            <div class="container">
                <div class="function_title">
                    <h2><strong>Thanks {BookingDetail.first_name}!</strong> <span>Your booking is confirmed!</span></h2>
                </div>
                <div class="function_meta">
                    <div class="callaction"><img src="/assets/images/phone_icon.svg" alt="" />+61 3 9429 8589</div>
                    <div class="callaction"><img src="/assets/images/map_pin_icon_2.svg" alt="" />280 Bridge Rd, Richmond 3121</div>
                </div>
                <div class="function_form_wrap">
                    <div class="booking_info_wrap flxrow flxwrap">
                        <div class="left_infobox">
                            <div class="info_title">
                                <div class="icon_box"><img src="/assets/images/user_person_icon.svg" alt="" /></div>Guest Info
                            </div>
                            <ul>
                                <li>{BookingDetail.first_name} {BookingDetail.last_name}</li>
                                <li>{BookingDetail.phone_number}</li>
                                <li>{BookingDetail.email}</li>
                            </ul>
                            <div class="info_title">
                                <div class="icon_box"><img src="/assets/images/table_icon.svg" alt="" /></div>Booking Info
                            </div>
                            <ul>
                                <li>Booking No : {BookingDetail.booking_no}</li>
                                <li>{BookingDetail.booking_date}</li>
                                <li>{BookingDetail.time}</li>
                                <li>{BookingDetail.number_of_people} Guests</li>
                                <li><strong>Status:</strong> {BookingDetail.status}</li>
                                <li><strong>Request / Occasion:</strong> {BookingDetail.note}</li>
                            </ul>
                        </div>
                        <div class="rightimagebox flxrow"><img src="/assets/images/booking-table.svg" alt="" /></div>
                        <div class="btnbox">
                            {(BookingDetail.status == 'Pending' || BookingDetail.status == 'Confirmed') && <>
                                <div class="inbtncol ">
                                    <Link to={`/bottomless-brunch/${BookingDetail.booking_no}`} class="ondabtn blackbtn withborder">Modify Table</Link>
                                </div>
                                <div class="inbtncol">
                                    <button type="button" class="ondabtn redbtn withborder" onClick={() => { window.$("#cancelTableBooking").modal("show"); }}>Cancel Table</button>
                                </div>
                            </>}
                            <div class="inbtncol">
                                <Link to="/bottomless-brunch" class="ondabtn greenbtn withborder">Book Again</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade loginsignupmodel" id="cancelTableBooking" >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <button type="button" className="btn-close flxrow" onClick={() => { window.$("#cancelTableBooking").modal("hide"); }}>
                        <img src="/assets/images/popup_close_icon.svg" alt="" />
                    </button>
                    <div className="modal-body pb-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="titlebox mt-2 mb-3"><h3>Cancel Table</h3></div>
                                <p className="text-center mb-3">Are you sure you want to cancel booking?</p>
                            </div>
                            <div className="col-sm-12 text-center mt-4">
                                <button type="button" disabled={ButtonDisabled} className="btn btn-lightpink mr-10" onClick={() => { window.$("#cancelTableBooking").modal("hide"); }}  > No</button>
                                <button type="button" disabled={ButtonDisabled} className="btn btn-success" onClick={cancelBooking}>{ButtonDisabled && <i className="spinner"></i>} Yes</button>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
