import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './layouts/App_route';
import UserRoute from './layouts/UserRoute';

import { BrowserRouter } from 'react-router-dom';

import Default from './layouts/Default';

// pages
import Home from './pages/Home.js';
import Product from './pages/product/Product.js';
import GiftVoucher from './pages/product/gift-voucher.js';
import FunctionBooking from './pages/product/function-booking.js';
import Aboutus from './pages/aboutus.js';
import Contactus from './pages/contactus';
import Career from './pages/career/Career.js';

import TableBooking from './pages/table-booking/table-booking.js';
import TableBookingSuccess from './pages/table-booking/table-booking-success';

import BottomlessBrunchBooking from './pages/book-bottomless-brunch/bottomless-brunch-booking';
import BottomlessBrunchSuccess from './pages/book-bottomless-brunch/bottomless-brunch-success';

import OrderHistory from './pages/user/order-history';
import OrderDetail from './pages/user/order-detail';

import BottomlessBrunchHistory from './pages/user/bottomless-brunch-history';
import BottomlessBrunchDetail from './pages/user/bottomless-brunch-detail';

import TableHistory from './pages/user/table-booking-history';
import TableBookingDetail from './pages/user/table-booking-detail';

import FunctionsHistory from './pages/user/functions-booking-history';
import FunctionsBookingDetail from './pages/user/functions-booking-detail';

import OrderSuccess from './pages/order-success';


function App() {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    {/* <UserRoute exact path="/change-password" component={ChangePassword} layout={Default} /> */}

                    <AppRoute exact path="/menu/:categori?" component={Product} layout={Default} />
                    <AppRoute exact path="/gift-voucher" component={GiftVoucher} layout={Default} />
                    <AppRoute exact path="/function-booking/:booking_id?" component={FunctionBooking} layout={Default} />

                    <AppRoute exact path="/table-booking/:booking_id?" component={TableBooking} layout={Default} />
                    <AppRoute exact path="/table-booking-success/:booking_id?" component={TableBookingSuccess} layout={Default} />

                    <AppRoute exact path="/bottomless-brunch/:booking_id?" component={BottomlessBrunchBooking} layout={Default} />
                    <AppRoute exact path="/bottomless-brunch-success/:booking_id?" component={BottomlessBrunchSuccess} layout={Default} />

                    <AppRoute exact path="/order-history" component={OrderHistory} layout={Default} />
                    <AppRoute exact path="/order-history/detail/:order_no?" component={OrderDetail} layout={Default} />

                    <AppRoute exact path="/bottomless-brunch-history" component={BottomlessBrunchHistory} layout={Default} />
                    <AppRoute exact path="/bottomless-brunch-history/detail/:booking_no?" component={BottomlessBrunchDetail} layout={Default} />

                    <AppRoute exact path="/table-booking-history" component={TableHistory} layout={Default} />
                    <AppRoute exact path="/table-booking-history/detail/:booking_no?" component={TableBookingDetail} layout={Default} />

                    <AppRoute exact path="/functions-booking-history" component={FunctionsHistory} layout={Default} />
                    <AppRoute exact path="/functions-booking-history/detail/:booking_no?" component={FunctionsBookingDetail} layout={Default} />

                    <AppRoute exact path="/order-success/:order_no?" component={OrderSuccess} layout={Default} />
                    <AppRoute exact path="/aboutus" component={Aboutus} layout={Default} />
                    <AppRoute exact path="/contact-us" component={Contactus} layout={Default} />
                    <AppRoute exact path="/career" component={Career} layout={Default} />

                    <AppRoute exact path="/" component={Home} layout={Default} />
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;
