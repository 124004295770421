import { useState, useEffect, useRef, useContext } from "react";
import { GET_FUNCTION_BOOKING_TIME, POST_FUNCTION_BOOKING, GET_TABLE_BOOK_DETAIL } from '../../helpers/url_helper';
import { post, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { is_login, img_view } from '../../helpers/Helper';
import SimpleReactValidator from 'simple-react-validator';
import { useHistory } from "react-router-dom";
import { MenuContext } from '../../layouts/Default';

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { addMonths } from 'date-fns';

export default function Home(props) {
    let history = useHistory();
    const { menu } = useContext(MenuContext);

    const [FunctionList, setFunctionList] = useState([
        { id: 'Birthday', value: 'Birthday' },
        { id: 'Engagement', value: 'Engagement' },
        { id: 'Corporate', value: 'Corporate' },
        { id: 'Other', value: 'Other' },
    ]);

    const [BookingDetail, setBookingDetail] = useState({ booking_no: '', booking_type: 'Functions Booking', date: '', time: '', first_name: '', number_of_people: 1, phone_number: '', email: '', function_type: '', food: '', drink_package: '', note: '' });
    const [dateList, setDateList] = useState([]);
    const [timeList, setTimeList] = useState([]);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [BookingSuccess, SetBookingSuccess] = useState(0);
    const [startDate, setStartDate] = useState('');

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [isLoader, setLoader] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            var date_res_data = await get(GET_FUNCTION_BOOKING_TIME);
            if (date_res_data.status) {
                setDateList(date_res_data.data.date_array);
            }

            if (props.match.params.booking_id) {
                var res_data = await post(GET_TABLE_BOOK_DETAIL, { booking_no: props.match.params.booking_id });
                if (res_data.status) {
                    if (res_data.data.booking_detail.status != 'Confirmed') {
                        history.push("/");
                    }
                    setStartDate(new Date(res_data.data.booking_detail.date));
                    setBookingDetail(res_data.data.booking_detail);
                    for (var date_value of date_res_data.data.date_array) {
                        if (date_value.date == res_data.data.booking_detail.date) {
                            setTimeList(date_value.time);
                        }
                    }
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, []);

    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name == 'date') {
            for (var date_value of dateList) {
                if (date_value.date == event.target.value) {
                    setTimeList(date_value.time);
                }
            }
            BookingDetail.time = '';
        }
        setBookingDetail(inputs => ({ ...BookingDetail, [event.target.name]: event.target.value }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {

            console.log(BookingDetail);

            SetButtonDisabled(true);
            var res_data = await post(POST_FUNCTION_BOOKING, BookingDetail);
            if (res_data.status) {
                SetBookingSuccess(1);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);

        }
    }

    const setDate = (event) => {
        setStartDate(event);
        var date = new Date(event),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
        var new_date =  [date.getFullYear(), mnth, day].join("-");

        setTimeList([]);
        for (var date_value of dateList) {
            if (date_value.date == new_date) {
                setTimeList(date_value.time);
            }else{
            }
        }
        BookingDetail.time = '';
        BookingDetail.date = new_date;

        setBookingDetail(inputs => ({ ...BookingDetail, ['date']: new_date }));
    }

    return (<>
        <div className="banner_wrapper">
            <div className="innerbanner">
                <div className="imagebox" style={{ 'backgroundImage': 'url(/assets/images/innerbanner.jpg)' }}></div>
                <div className="bann_captionbox">
                    <div className="container-fluid">
                        <h1>Functions</h1>
                        <div className="opentiming">100% Gluten Free</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="functionbooking_wrap">
            <div className="container">
                <div className="function_title">
                    <h2>Functions Booking</h2>
                </div>
                <div className="function_meta">
                    <div className="callaction"><img src="/assets/images/phone_icon.svg" alt="" />+61 3 9429 8589</div>
                    <div className="callaction"><img src="/assets/images/map_pin_icon_2.svg" alt="" />280 Bridge Rd, Richmond 3121</div>
                </div>
                {menu.function_booking_menu && <div className="text-center mb-5">
                    <a href={img_view(menu.function_booking_menu, '', '', false)} target="_blank" class="pdf_menu"> View Function Booking Menu</a>
                </div>}
                {!BookingSuccess && <div className="function_form_wrap">
                    <form action="" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="form_box">
                                    <label htmlFor="">Name *</label>
                                    <input type="text" name="first_name" value={BookingDetail.first_name} onChange={handleInputChange} class="input_text" placeholder="Your First Name" />
                                    {simpleValidator.current.message('first name', BookingDetail.first_name, 'required')}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form_box">
                                    <label htmlFor="">Email *</label>
                                    <input type="text" name="email" value={BookingDetail.email} onChange={handleInputChange} class="input_text" placeholder="Your Email Address" />
                                    {simpleValidator.current.message('email', BookingDetail.email, 'required|email')}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form_box">
                                    <label htmlFor="">Phone *</label>
                                    <input type="text" name="phone_number" maxLength={12} value={BookingDetail.phone_number} onChange={handleInputChange} class="input_text" placeholder="Your Phone Number" />
                                    {simpleValidator.current.message('phone number', BookingDetail.phone_number, 'required|phone')}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form_box">
                                    <label htmlFor="">Number Of Guests *</label>
                                    <select name="number_of_people" value={BookingDetail.number_of_people} onChange={handleInputChange} class="input_text">
                                        {Array.from(Array(40), (e, i) => {
                                            return (<option value={++i}>{i}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form_box">
                                    <label htmlFor="">Preferred Date *</label>
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={startDate}
                                        minDate={new Date()}
                                        maxDate={addMonths(new Date(), 6)}
                                        onChange={setDate}
                                        className="input_text"
                                        placeholderText="Select Date"
                                    />
                                    {/* <select name="date" value={BookingDetail.date} onChange={handleInputChange} class="input_text">
                                        <option value="">Select Date</option>
                                        {dateList && Object.entries(dateList).map(([key, date]) => {
                                            return (<option value={date.date} key={key}>{date.date}</option>);
                                        })}
                                    </select> */}
                                    {simpleValidator.current.message('date', BookingDetail.date, 'required')}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form_box">
                                    <label htmlFor="">Start Time *</label>
                                    <select name="time" value={BookingDetail.time} onChange={handleInputChange} class="input_text">
                                        <option value="">Select Time</option>
                                        {timeList && Object.entries(timeList).map(([key, date]) => {
                                            return (<option value={date.time} key={key}>{date.time}</option>);
                                        })}
                                    </select>
                                    {simpleValidator.current.message('time', BookingDetail.time, 'required')}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form_box">
                                    <label htmlFor="">Type of Function *</label>
                                    <select name="function_type" value={BookingDetail.function_type} onChange={handleInputChange} className="input_text">
                                        <option value="">Select Function</option>
                                        {FunctionList && Object.entries(FunctionList).map(([key, value]) => {
                                            return (<option key={key} value={value.id}>{value.value}</option>)
                                        })}
                                    </select>
                                    {simpleValidator.current.message('function type', BookingDetail.function_type, 'required')}
                                </div>
                            </div>
                            {/* <div className="col-lg-4">
                                <div className="form_box">
                                    <label htmlFor="">Food Selection *</label>
                                    <select name="food" value={BookingDetail.food} onChange={handleInputChange} className="input_text">
                                        <option value="">Select Food</option>
                                        <option value="A la cart">A la cart</option>
                                        <option value="Banquete">Banquete</option>
                                    </select>
                                    {simpleValidator.current.message('food', BookingDetail.food, 'required')}
                                </div>
                            </div> */}
                            <div className="col-lg-4">
                                <div className="form_box">
                                    <label htmlFor="">Drink Package</label>
                                    <select name="drink_package" value={BookingDetail.drink_package} onChange={handleInputChange} className="input_text">
                                        <option value="">Select Drink Package</option>
                                        <option value="2 hours - $45">2 hours - $45</option>
                                        <option value="3 hours - $60">3 hours - $60</option>
                                    </select>
                                    {simpleValidator.current.message('drink package', BookingDetail.drink_package, 'required')}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form_box">
                                    <label htmlFor="">Additional Information</label>
                                    <textarea name="note" value={BookingDetail.note} onChange={handleInputChange} className="input_text" placeholder="Message"></textarea>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="btnbox form_box">
                                    <button type="submit" disabled={ButtonDisabled} class="ondabtn bigbtn greenbtn" >{ButtonDisabled && <i className="spinner"></i>} ENQUIRY NOW</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <p className="p_note">Please note Saturdays and Sundays incur a 10% Weekend Surcharge. Public Holidays incur a 15% Surcharge.</p>
                    <div className="payment_note"><strong>Payments:</strong> VISA, MasterCard, Cash, Apple Pay, Android Pay, Debit</div>
                </div>}
                {BookingSuccess > 0 && <div className="function_form_wrap">
                    <div className="sucess_message">
                        <div className="in_sucessmsg flxcol">
                            <strong>Thank you for your enquiry. <br/> We will get back to you soon!</strong>
                            <p>A confirmation email was sent to <span>{BookingDetail.email}</span></p>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    </>)
}
